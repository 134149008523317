<template>

  <b-card>
    <div class="d-flex justify-content-start">
      <button
        class="compact ui button text-height"
        @click="refreshCommon"
      >
        <refresh-cw-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Tải lại
      </button>
    </div>
    <good-table
      ref="vgTable"
      class="mt-1"
      :columns="columns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      :selectable="false"
      @column-filter="columnFilter"
      @page-change="pageChange"
    >
      <div
        slot="custom-filter"
        slot-scope="{ props }"
      >
        <span v-if="props.column.field == 'timeStamp'">
          <date-picker
            v-model="props.column.filterOptions.filterValue"
            type="date"
            range
            placeholder="Chọn thời gian"
            format="DD/MM/YYYY"
            class="date-picker-table w-100"
            @input="columnFilterTable"
          />
        </span>
        <span v-else-if="props.column.field !== 'STT'">
          <input
            v-model="props.column.filterOptions.filterValue"
            type="text"
            class="vgt-input"
            placeholder="Tìm kiếm"
          >
        </span>
      </div>
      <template
        slot="custom-row"
        slot-scope="{ props }"
      >
        <span
          v-if="props.column.field == 'module'"
          class="uppercase-first-letter"
        >
          {{ props.row.module }}
        </span>
      </template>
    </good-table>
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import { RefreshCwIcon } from 'vue-feather-icons'
import addCssSemantic from '@/mixins/mixins'
import GoodTable from '@/components/GoodTable.vue'
import END_POINTS from '@/api/endpoints'
import * as dayjs from 'dayjs'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    BCard,
    GoodTable,
    RefreshCwIcon,
    DatePicker,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới phân quyền lĩnh vực - nhóm người dùng',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên người dùng',
          field: 'username',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Lịch sử thao tác',
          field: 'module',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thời gian',
          field: 'timeStamp',
          thClass: 'text-center',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
      ],
      rows: [],
      total: 0,
      componentName: null,
      payload: {
        username: null,
        startDate: null,
        endDate: null,
        module: '',
        pageNumber: 1,
        pageSize: 10,
      },
      isLoading: false,
      size: null,
    }
  },
  created() {
    this.getDataNhatKyHeThong()
  },
  methods: {
    getDataNhatKyHeThong() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.NHAT_KY_HE_THONG.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data || []
            this.total = res.data.totalCount || 0
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.NHAT_KY_HE_THONG.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        username: data?.username ? data.username.replace(/\s+/g, ' ').trim() : null,
        startDate: data?.timeStamp && data.timeStamp[0] ? dayjs(data.timeStamp[0]).format('YYYY-MM-DD') : null,
        endDate: data?.timeStamp && data.timeStamp[1] ? dayjs(data.timeStamp[1]).format('YYYY-MM-DD') : null,
        module: data?.module ? data.module.replace(/\s+/g, ' ').trim() : '',
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.NHAT_KY_HE_THONG.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    refreshCommon() {
      this.payload = {
        username: null,
        startDate: null,
        endDate: null,
        module: '',
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.vgTable.$refs.vbTables.reset()
      this.columnFilter()
    },
  },
}
</script>
