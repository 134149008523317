<template>
  <nhat-ky-he-thong />
</template>

<script>
import NhatKyHeThong from '@/modules/mot-cua/components/pages/NhatKyHeThong.vue'

export default {
  name: 'TrangChu',
  components: {
    NhatKyHeThong,
  },
}
</script>

<style scoped></style>
